var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Add Trigger")])]),_c('CCardBody',[_c('CForm',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.validator();_vm.submit()}}},[_c('CRow',[_c('CCol',[_c('small',[_vm._v("Use comma to add more fields, eg Phone Number, Telegram ID or E-mail.")])])],1),_c('br'),_c('CRow',[_c('CCol',{attrs:{"sm":"3"}},[_c('label',[_vm._v("User")]),_c('model-select',{attrs:{"options":_vm.users,"placeholder":"Please Select"},model:{value:(_vm.form.user_id.val),callback:function ($$v) {_vm.$set(_vm.form.user_id, "val", $$v)},expression:"form.user_id.val"}},[_c('small',{staticClass:"form-text text-muted w-100"},[_vm._v(_vm._s(_vm.form.user_id.inv))])])],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CInput',{attrs:{"description":_vm.form.name.inv,"label":"Name"},model:{value:(_vm.form.name.val),callback:function ($$v) {_vm.$set(_vm.form.name, "val", $$v)},expression:"form.name.val"}})],1),_c('CCol',{attrs:{"sm":"3"}},[_c('label',[_vm._v("Processes")]),_c('model-select',{attrs:{"options":_vm.input_proccesses,"placeholder":"Please Select"},model:{value:(_vm.form.input_proccess_id.val),callback:function ($$v) {_vm.$set(_vm.form.input_proccess_id, "val", $$v)},expression:"form.input_proccess_id.val"}},[_c('small',{staticClass:"form-text text-muted w-100"},[_vm._v(_vm._s(_vm.form.input_proccess_id.inv))])])],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CSelect',{attrs:{"description":_vm.form.name.inv,"label":"State","options":[
                                        {value: 'true', label:'Active'},
                                        {value: 'false', label:'Inactive'} ],"placeholder":"Please Select"},on:{"change":function($event){_vm.form.is_active.val=$event.target.value}},model:{value:(_vm.form.is_active.val),callback:function ($$v) {_vm.$set(_vm.form.is_active, "val", $$v)},expression:"form.is_active.val"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"3"}},[_c('CInput',{attrs:{"description":_vm.form.compare_with.inv,"label":"Compare with"},model:{value:(_vm.form.compare_with.val),callback:function ($$v) {_vm.$set(_vm.form.compare_with, "val", $$v)},expression:"form.compare_with.val"}})],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CSelect',{attrs:{"description":_vm.form.compare_type.inv,"label":"Compare Type","options":[
                                        {value: '==', label:'=='},
                                        {value: '!=', label:'!='},
                                        {value: '>=', label:'>='},
                                        {value: '<=', label:'<='},
                                        {value: '<', label:'<'},
                                        {value: '>', label:'>'} ],"placeholder":"Please Select"},on:{"change":function($event){_vm.form.compare_type.val=$event.target.value}},model:{value:(_vm.form.compare_type.val),callback:function ($$v) {_vm.$set(_vm.form.compare_type, "val", $$v)},expression:"form.compare_type.val"}})],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CTextarea',{attrs:{"description":_vm.form.phone_numbers.inv,"label":"Phone Numbers","rows":"1"},model:{value:(_vm.form.phone_numbers.val),callback:function ($$v) {_vm.$set(_vm.form.phone_numbers, "val", $$v)},expression:"form.phone_numbers.val"}})],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CTextarea',{attrs:{"description":_vm.form.telegram_ids.inv,"label":"Telegram IDs","rows":"1"},model:{value:(_vm.form.telegram_ids.val),callback:function ($$v) {_vm.$set(_vm.form.telegram_ids, "val", $$v)},expression:"form.telegram_ids.val"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"3"}},[_c('CTextarea',{attrs:{"description":_vm.form.email_addresses.inv,"label":"E-mails","rows":"1"},model:{value:(_vm.form.email_addresses.val),callback:function ($$v) {_vm.$set(_vm.form.email_addresses, "val", $$v)},expression:"form.email_addresses.val"}})],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CTextarea',{attrs:{"description":_vm.form.callback_text.inv,"label":"Callback Text","rows":"1"},model:{value:(_vm.form.callback_text.val),callback:function ($$v) {_vm.$set(_vm.form.callback_text, "val", $$v)},expression:"form.callback_text.val"}})],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CTextarea',{attrs:{"description":_vm.form.detail.inv,"label":"Description","rows":"1"},model:{value:(_vm.form.detail.val),callback:function ($$v) {_vm.$set(_vm.form.detail, "val", $$v)},expression:"form.detail.val"}})],1)],1),_c('br')],1),_c('br'),_c('div',{staticClass:"form-group form-actions"},[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){_vm.validator();_vm.submit()}}},[_vm._v(" Submit")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }